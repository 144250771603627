//React
import React from 'react'

//Gatsby
import {
  FaCommentDots,
  FaRobot,
  FaShare,
  FaHandshake,
  FaHandsHelping,
  FaMoneyBillAlt,
  FaMoneyCheckAlt,
  FaRegBuilding,
  FaHome,
  FaUsers,
  FaRegLightbulb,
  FaBullseye,
  FaMobileAlt,
  FaAward,
  FaClipboardList,
  FaUserShield,
  FaHammer,
  FaNewspaper,
  FaUmbrellaBeach,
} from 'react-icons/fa'
import {
  MdOndemandVideo,
  MdPhonelinkRing,
  MdShowChart,
  MdPeople,
  MdSlowMotionVideo,
  MdLocalPlay,
  MdQuestionAnswer,
  MdRecentActors,
  MdPhoneIphone,
  MdLocalHospital,
  MdLock,
  MdTouchApp,
  MdRestaurant,
  MdSchool,
  MdLocalOffer,
  MdGroup,
  MdFavoriteBorder,
} from 'react-icons/md'
import {
  IoMdStopwatch,
  IoMdConstruct,
  IoIosFitness,
  IoMdImages,
} from 'react-icons/io'
import {
  AiOutlineVideoCamera,
  AiFillThunderbolt,
  AiOutlineAudit,
  AiFillDatabase,
  AiTwotoneShop,
  AiOutlineBank,
  AiFillNotification,
} from 'react-icons/ai'
import { FiSun, FiThumbsUp } from 'react-icons/fi'
import { GoMegaphone } from 'react-icons/go'
import { GiHealthNormal, GiDirectorChair, GiMirrorMirror } from 'react-icons/gi'
import {
  RiHospitalLine,
  RiHomeHeartLine,
  RiHotelLine,
  RiHeartPulseFill,
} from 'react-icons/ri'
import { BiBuildings, BiChurch } from 'react-icons/bi'
import { HiOutlineMicrophone } from 'react-icons/hi'
import { BsBook } from 'react-icons/bs'

const fillColor = (props) => {
  return props.color && props.simple
    ? `fill-${props.color}-1`
    : props.iconFill && props.iconColor
    ? `fill-${props.iconFill}-1 ${props.iconColor}-1`
    : 'fill-white-1'
}
const Snippet = (props) => (
  <div
    class={
      props.simple
        ? ``
        : `feature-detail-icon-outer-container d-flex flex-column align-items-center justify-content-center bg-${props.color}-4`
    }
    style={{ marginLeft: 0 }}
  >
    <div
      class={
        props.simple
          ? ``
          : `feature-detail-icon-container d-flex flex-column align-items-center justify-content-center bg-${props.color}-1`
      }
    >
      {props.context === 'microphone' ? (
        <HiOutlineMicrophone class={`feature-detail-icon`} color="white" />
      ) : null}
      {props.context === 'buildings' ? (
        <BiBuildings class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'church' ? (
        <BiChurch class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'award' ? (
        <FaAward class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'chatbot' ? (
        <FaCommentDots class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'lock' ? (
        <MdLock class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'video' ? (
        <MdOndemandVideo class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'delivery' ? (
        <MdPhonelinkRing class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'moderation' ? (
        <FaRobot class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'social' ? (
        <FaShare class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'analytics' ? (
        <MdShowChart class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'videocamera' ? (
        <AiOutlineVideoCamera
          class={`feature-detail-icon ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'people' ? (
        <MdPeople class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'handshake' ? (
        <FaHandshake class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'helping' ? (
        <FaHandsHelping class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'money' ? (
        <FaMoneyBillAlt class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'moneycheck' ? (
        <FaMoneyCheckAlt class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'building' ? (
        <FaRegBuilding class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'users' ? (
        <FaUsers class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'home' ? (
        <FaHome class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'bullseye' ? (
        <FaBullseye class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'sun' ? (
        <FiSun class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'slowmo' ? (
        <MdSlowMotionVideo class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'lightning' ? (
        <AiFillThunderbolt class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'stopwatch' ? (
        <IoMdStopwatch class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'construction' ? (
        <IoMdConstruct class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'conversation' ? (
        <MdQuestionAnswer class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'ticket' ? (
        <MdLocalPlay class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'recentactors' ? (
        <MdRecentActors class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'hospital' ? (
        <MdLocalHospital class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'iphone' ? (
        <FaMobileAlt class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'shield' ? (
        <FaUserShield class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'lightbulb' ? (
        <FaRegLightbulb class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'megaphone' ? (
        <GoMegaphone class={`feature-detail-icon-light ${fillColor(props)}`} />
      ) : null}
      {props.context === 'health' ? (
        <GiHealthNormal
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'clipboard' ? (
        <FaClipboardList
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'audit' ? (
        <AiOutlineAudit
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'database' ? (
        <AiFillDatabase
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'notification' ? (
        <AiFillNotification
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'storefront' ? (
        <AiTwotoneShop
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'bank' ? (
        <AiOutlineBank
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'touchapp' ? (
        <MdTouchApp class={`feature-detail-icon-light ${fillColor(props)}`} />
      ) : null}
      {props.context === 'thumbsup' ? (
        <FiThumbsUp class={`feature-detail-icon-light ${fillColor(props)}`} />
      ) : null}
      {props.context === 'homehealth' ? (
        <RiHospitalLine
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'assistedliving' ? (
        <RiHomeHeartLine
          class={`feature-detail-icon-light ${fillColor(props)}`}
        />
      ) : null}
      {props.context === 'fitness' ? (
        <IoIosFitness class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'hammer' ? (
        <FaHammer class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'restaurant' ? (
        <MdRestaurant class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'directorchair' ? (
        <GiDirectorChair class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'mirror' ? (
        <GiMirrorMirror class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'hotelbuilding' ? (
        <RiHotelLine class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'heartpulse' ? (
        <RiHeartPulseFill class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'book' ? (
        <BsBook class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'newspaper' ? (
        <FaNewspaper class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'graduation' ? (
        <MdSchool class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'tag' ? (
        <MdLocalOffer class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'group' ? (
        <MdGroup class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'heart' ? (
        <MdFavoriteBorder class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'imagespread' ? (
        <IoMdImages class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.context === 'beach' ? (
        <FaUmbrellaBeach class={`feature-detail-icon ${fillColor(props)}`} />
      ) : null}
      {props.text ? (
        <div
          class={`display-3 font-weight-medium ${props.color}-6 ${fillColor(
            props
          )}`}
        >
          {props.text}
        </div>
      ) : null}
    </div>
  </div>
)

export default Snippet
